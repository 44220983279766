@import url('https://fonts.googleapis.com/css?family=Playfair+Display');

body {
  background-color: rgba(183, 255, 227, 0.678);
  font-family: 'Playfair Display', serif;
}

header {
  background: #222;
  color: #efefef;
  padding: 2em;
  font-size: 1.2em;
  font-weight: bold;
}

header h3 {
  color:  rgba(255, 150, 103)
}

footer {
  background-color: #222;
  color: #444444;
  display: flex;
  align-items: center;
  justify-content: center;
}

footer p a {
  color: #444444;
  text-decoration: none;
}

#main {
  width: 52em;
  margin: 0 auto;
  background: #FFF;
  min-height: 100vh;
  display: grid;
  grid-template-rows: 15em 1fr 40px;
}

#container {
  padding: 2em;
}

#PickerContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}


#copyBox {
  z-index: -1000;
  position: absolute;
  top: 0;
  left: -9999px;
}

.Button {
  font-family: 'Playfair Display', serif;
  font-weight: bold;
  font-size: 1em;
  margin: 1em;
  padding: .5em 2em;
  background-color: rgba(255, 150, 103, .5);
}

.Button:hover {
  background: linear-gradient( rgba(255, 150, 103, .2),rgba(255, 150, 103, .4)  );
}

.Button:active {
  background-color: rgba(255, 150, 103, .7);
}

.Picker {
  text-align: center;
}

@media screen and (max-width: 32em) {

  header {
    display: flex;
    align-items: center;
  }

  header h3 {
    display: none;
  }

  footer p {
    font-size: .5em;
  }

  #main {
    width: 95%;
    margin: 0 auto;
  }

  #PickerContainer {
    flex-direction: column;
    justify-content: center;
  }
}